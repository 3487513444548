import {
  ActionIcon,
  Anchor,
  Group,
  Image,
  SimpleGrid,
  Text
} from "@mantine/core";
import { LanguageSwitcher, UnitSwitch } from "@/components";
import { IconAlien } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { theme } from "@/theme/theme";
import { useSettings } from "@/hooks";
import { KEY } from "@/utils/const";
import { useMemo } from "react";

export const NavBar = () => {
  const { i18n } = useTranslation();
  const { debugEnabled } = useSettings();

  //create method returing current date in the format of "dddd DD.MM.YYYY" based on selected locale from i18n with vanilla JS Date object
  const displayDate = useMemo(() => {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return date.toLocaleDateString(i18n.language, options);
  }, [i18n.language]);
  return (
    <SimpleGrid cols={3} style={{ alignItems: "center" }} visibleFrom="s">
      <div>
        <Group>
          <Image src="mincovka_icon1.png" alt="Mincovka Icon" w={25} ml={10} />
          <Text size="sm" c={theme.colors.defaultText}>
            <Anchor
              href="mailto:info@mincovka.online"
              target="_blank"
              underline="hover"
              c={theme.colors.defaultText}
            >
              info@mincovka.online
            </Anchor>
          </Text>
          <ActionIcon
            display={debugEnabled ? "inherit" : "none"}
            component={IconAlien}
            variant="transparent"
            size={15}
            onClick={() => {
              localStorage.setItem(KEY.currenciesAmount, "true");
              window.location.reload();
            }}
          />
          {/* <BuggyComponent /> */}
        </Group>
      </div>
      <div>
        <Text c={theme.colors.defaultText} fw="normal" tt="uppercase" size="sm">
          {displayDate}
        </Text>
      </div>
      <div>
        <Group justify="flex-end" mr={5}>
          <LanguageSwitcher />
          {/* <MenuContainer /> */}
          <UnitSwitch />
          {/* <Text mx={20} c={theme.colors.defaultText}>
              Blog
            </Text> */}

          {/* <Avatar
              size="sm"
              radius="xl"
              src="https://i.pravatar.cc/300"
              mr={25}
            /> */}
        </Group>
      </div>
    </SimpleGrid>
  );
};
